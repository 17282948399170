
import React,{memo, useEffect} from 'react'
import './style.css'
import { Link,useParams, useSearchParams,Navigate ,useNavigate,useLocation} from 'react-router-dom'

import Mabesha1 from '../../assets/ProductGallary/Mabesha3407.jpg'
import Mabesha2 from '../../assets/ProductGallary/Mabesha3399.jpg'
import Mabesha3 from '../../assets/ProductGallary/Mabesha3386.jpg'
import Mabesha4 from '../../assets/ProductGallary/Mabesha3383.jpg'

function SeeSimilarSareesMemorize({similarProduct,imgUrlThumb}) {
  const simProd = useLocation();

  const navigate = useNavigate();
  // console.log('first path', simProd)
  const url = simProd.pathname;
  const catURL = url.substring(0, url.lastIndexOf('/', url.lastIndexOf('/') - 1));
  const toDetailsPage = (route) => {
    const loadTop = document.querySelector('#root')
    navigate(route)
    if (loadTop) {
      loadTop.scrollIntoView({ behavior: "smooth" });
    }
  }
  // useEffect(() => {
  //   console.log("items", similarProduct);
  //   console.log("items2", imgUrlThumb)
  // }, [similarProduct, imgUrlThumb]);
  return (
    <>
      <div className="flex justify-center my-10">
        {/* <img src={seeSimilarSaree} alt="seseeSimilarSaree" /> */}
        <h1 className='font-Lucida text-[3em] font-medium text-red-600 red-title-shadow'>See Similar Sarees</h1>
      </div>
      
	  
      <div className='my-5'>
        <div className='relative space-x-20 py-10 px-6 lg:px-0 lg:mx-60'>
            {/* <div className=' lg:flex  justify-center space-x-10'> */}
            <div className=' grid grid-cols-1 lg:grid-cols-4 sx:grid-cols-2 xs:grid-cols-2 gap-5 md:gap-10'>
              {similarProduct?.slice(0,4)?.map((product, index)=>{
                const prodTitle = product?.product_name
                const prodColor = product?.color_name
                const slug = ((prodTitle)? prodTitle : "").replace(/\W+/g, '-') +((prodColor)? "-"+prodColor : "").replace(/\W+/g, '-')+'-'+product.id
                const titleToLink = "/../../saree/"+slug.toLowerCase();
                
                
                const updatedSimUrl = url.substring(0, url.lastIndexOf('/'));
                return(
                  <>
                  {/* <img key={index} onClick={()=>toDetailsPage(titleToLink)} className='lg:w-44 lg:h-48 lg:hover:scale-125  transition duration-1000 shrink-0 rounded-md hover:shadow-xl cursor-pointer' src={imgUrlThumb+"/"+product?.product_image.split(",")[0]} alt='Mabesha1' style={{objectFit: 'cover'}}/> */}
                  <Link to={updatedSimUrl+'/'+slug.toLowerCase()}><img key={index} className='lg:w-44 lg:h-48 lg:hover:scale-125  transition duration-1000 shrink-0 rounded-md hover:shadow-xl cursor-pointer' src={imgUrlThumb+"/"+product?.product_image.split(",")[0]} alt='Mabesha1' style={{objectFit: 'cover'}}/></Link>
                  </>
                  
                )
              })}
          </div>
        </div>
      </div>

      <div className="flex">
        {/* <button onClick={() => navigate('../productlist/'+categoryId)} className="text-sm bg-red-600 text-white font-semibold p-2 rounded-md w-32 mb-3 mx-auto"> */}
        <Link to={catURL} className="text-sm text-center bg-red-600 text-white font-semibold p-2 rounded-md w-32 mb-3 mx-auto">
          View More
        </Link>
      </div>
    </>
  )
}
const SeeSimilarSarees = memo(SeeSimilarSareesMemorize)
export default SeeSimilarSarees