import React,{memo,useState,useEffect} from 'react'
import Layout from '../../components/layout/Layout'
import AboutUsbanners from '../../assets/Banner/ExploreOurcollection.jpg'
import Owner from '../../assets/Banner/singleWoner.jpg'
import { Link, useNavigate, useParams, } from 'react-router-dom'
import { cmsAboutUs } from '../../api'
import { useSelector, useDispatch } from "react-redux";
import Loading from '../../components/Loding/Loding'

const Preloader = () => <div className="preloader flex-col text-[#fff]"><Loading />Loading...</div>;

const AboutUsMemorized = () => {

  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAboutUs();
  }, []);

  const getAboutUs = () => {
    dispatch(cmsAboutUs())
      .then(res => {
        setData(res.message);
        console.log(res.message.from_ceo);
      })
      .catch(() => {
        console.log("some error");
      })
  }
  return (
    <Layout>
      {/* {loading && <Preloader />} */}
      {/* {loading && <Loading />} */}
      
      <div className='w-full 
      h-auto  text-center mb-60 '>
        <img className='relative  ' src={Owner} alt='about us banner' />
        <div className='absolute  md:right-[4rem] lg:right-60 top-72 right-5'>
          <p className='w-60 mt-5 text-sm  font-serif text-left text-red-600' dangerouslySetInnerHTML={{__html:data?.from_ceo}}></p>
        </div>
        {/* <svg className='absolute -bottom-20 left-0 ' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fill="#ffffff" fillOpacity="1" d="M0,256L80,266.7C160,277,320,299,480,266.7C640,235,800,149,960,122.7C1120,96,1280,128,1360,144L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
        </svg> */}
        {/* <div className='flex  justify-center space-x-20 mx-60'> */}
        <div className='flex space-x-20 py-10 px-10 lg:px-0 lg:mx-60'>
          
          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-1 gap-4">

            <div className=''>
              <div className=' text-left ' dangerouslySetInnerHTML={{__html:data?.who_we}}></div>
            </div>
            <div className='mt-20 lg:mt-0'>
              <div className=' text-left ' dangerouslySetInnerHTML={{__html:data?.why_we}}></div>
            </div>

          </div>
        </div>
      
      </div>
     
    </Layout>
  )
}
const AboutUs = memo(AboutUsMemorized)
export default AboutUs